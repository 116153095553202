import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Events from '../../containers/events'
import OverviewLayout from '../../containers/layout/overview-layout'

const EventList = ({ data, pageContext, location }) => (
  <OverviewLayout
    location={location}
    pageContext={pageContext}
    pageData={data.page.edges[0].node}
    subCategory={pageContext.subCategory}
  >
    <Events
      list={
        pageContext.subCategory
          ? data.subList.edges.map((edge) => edge.node)
          : data.list.edges.map((edge) => edge.node)
      }
      small={true}
    />
  </OverviewLayout>
)

export const query = graphql`
  query EventListQuery($skip: Int!, $limit: Int!, $category: String) {
    page: allContentfulPagina(
      filter: { id: { eq: "8c4a8436-1a88-51b4-894e-ceb036dfd597" } }
    ) {
      edges {
        node {
          headerTitel
          headerSubtitel
          headerButtonTekst
          headerButtonLink
          headerImage {
            file {
              url
              fileName
              contentType
            }
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }

    list: allContentfulEvenement(
      sort: { fields: date, order: ASC }
      filter: { themes: { elemMatch: { title: { eq: $category } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          slug
          title
          day: date(locale: "nl-Nl", formatString: "DD")
          month: date(locale: "nl-Nl", formatString: "MMM")
          themes {
            id
            title
          }
        }
      }
    }

    subList: allContentfulEvenement(
      sort: { fields: date, order: ASC }
      filter: { subjects: { elemMatch: { title: { eq: $category } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          slug
          title
          day: date(locale: "nl-Nl", formatString: "DD")
          month: date(locale: "nl-Nl", formatString: "MMM")
          themes {
            id
            title
          }
        }
      }
    }
  }
`

EventList.propTypes = {
  headingStyle: PropTypes.object,
}

export default EventList
